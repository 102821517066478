import { datadogRum } from '@datadog/browser-rum';
import { AuthHelpers, ErrorTracking } from '@inteliam/foundation/lib/utils';

import * as Sentry from '@sentry/react';
import { ErrorInfo } from 'react';

import { ConfigContextType } from '@core/contexts';

const initSentry = (config: ConfigContextType) => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    release: `iSuite@${config.APP_VERSION}`,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    sampleRate: 1,
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    environment: config.SENTRY_ENV,
    ignoreErrors: ['Failed to fetch dynamically imported module'],
  });
};

const initDataDog = (config: ConfigContextType) => {
  datadogRum.init({
    applicationId: '7734ec09-2fdc-4759-8b5f-14d0715752b4',
    clientToken: 'pub1bc44554726ecd1032de00279d7758b1',
    site: 'datadoghq.eu',
    service: 'iSuite',
    env: config.SENTRY_ENV,
    version: config.APP_VERSION,
    replaySampleRate: config.DD_REPLAY_SAMPLING_RATE,

    sampleRate: 100,
    trackInteractions: true,
  });
};

export const initTracking = (config: ConfigContextType): void => {
  const environment =
    config.NODE_ENV === 'development' ? 'development' : 'production';

  const isDevelopment = environment === 'development';
  if (!isDevelopment && config.SENTRY_DSN) {
    initSentry(config);
  }
  if (['prod', 'test', 'UAT'].includes(config.SENTRY_ENV)) {
    initDataDog(config);
  }
};

export const report = (
  error: Error | unknown,
  source?: string,
  errorInfo?: ErrorInfo,
  extras: Array<{ key: string; value: unknown }> = []
): void => {
  const environment =
    import.meta.env?.MODE === 'development' ? 'development' : 'production';

  const token = AuthHelpers.getAccessToken();
  if (token !== undefined) {
    const jwt = AuthHelpers.decodeToken(token);

    extras.push(
      {
        key: 'token-expired',
        value: jwt.exp <= Math.floor(Date.now() / 1000),
      },
      {
        key: 'user',
        value: jwt.user.id,
      }
    );
  }

  extras.push({
    key: 'impersonating',
    value: AuthHelpers.isImpersonating(),
  });

  ErrorTracking.report({
    error,
    errorInfo,
    environment: environment,
    extras,
    source,
  });
};
