import clsx from 'clsx';

import * as React from 'react';

import { TextLoader } from '@shared/components/loaders';

// https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const __DEFAULT_ELEMENT__ = 'div' as const;
type TextLoaderProps = React.ComponentProps<typeof TextLoader>;
type TextOwnProps<E extends React.ElementType = typeof __DEFAULT_ELEMENT__> = {
  component?: E;
  isLoading?: boolean;
  loaderProps?: TextLoaderProps;
  weight?: 'normal' | 'medium' | 'semi-bold' | 'bold' | 'light' | 'bolder';
  /**
   * @default 'heading5'
   * title: 2xl
   * subtile: xs
   * heading6: text-1xs
   * heading5: text-xs
   * heading4: text-sm
   * heading3: text-base
   * heading2: text-lg
   * heading1: text-xl
   *
   */
  heading?:
    | 'title'
    | 'subtile'
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'heading6'
    | 'heading-3xl'
    | 'heading-4xl'
    | 'heading-5xl'
    | 'heading-6xl'
    | 'heading-7xl';
};

type TextProps<E extends React.ElementType> = TextOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof TextOwnProps>;

function InnerText<T extends React.ElementType = typeof __DEFAULT_ELEMENT__>(
  {
    component: Component,
    id,
    isLoading,
    loaderProps,
    children,
    heading = 'heading5',
    weight = 'normal',
    className, // className should NOT include any text size/weight classes
    ...rest
  }: TextProps<T>,
  ref: React.ForwardedRef<T>
) {
  const ComponentToUse = Component || __DEFAULT_ELEMENT__;

  return (
    // @ts-expect-error: problem with forwardRef
    <ComponentToUse
      className={clsx(
        {
          'border-l-4 pl-2 mt-3 mb-4 text-2xl': heading === 'title',
          '-mt-2 mb-3 text-xs': heading === 'subtile',
          'text-1xs': heading === 'heading6',
          'text-xs': heading === 'heading5',
          'text-sm': heading === 'heading4',
          'text-base': heading === 'heading3',
          'text-lg': heading === 'heading2',
          'text-xl': heading === 'heading1',
          'text-3xl': heading === 'heading-3xl',
          'text-4xl': heading === 'heading-4xl',
          'text-5xl': heading === 'heading-5xl',
          'lg:text-6xl sm:text-3xl': heading === 'heading-6xl',
          'text-7xl': heading === 'heading-7xl',
          'font-bold': weight === 'bold',
          'font-semibold': weight === 'semi-bold',
          'font-light': weight === 'light',
          'font-medium': weight === 'medium',
          'font-normal': weight === 'normal',
        },
        className
      )}
      {...rest}
      id={id}
      data-cy={id}
      data-testid={id}
      ref={ref}
    >
      {isLoading ? <TextLoader {...loaderProps} /> : children}
    </ComponentToUse>
  );
}

export const Text = React.forwardRef(InnerText);
