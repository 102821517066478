import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import Pages500 from '@core/pages/common/500';
import Logout from '@core/pages/common/logout';

import { useEssentials } from '@core/contexts';

const AuthenticatedApp = React.lazy(() => import('./authenticated-app'));
const UnAuthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

const App: React.FCC = () => {
  const { user, config } = useEssentials();
  // eslint-disable-next-line no-console
  console.log(`Inteliam iSuite version ${config.APP_VERSION}`);

  return (
    // eslint-disable-next-line unicorn/no-null
    <React.Suspense fallback={null}>
      <Routes>
        <Route path={'/logout'} element={<Logout />} />
        <Route path={'/500'} element={<Pages500 />} />
        <Route
          path='*'
          element={user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
        ></Route>
      </Routes>
    </React.Suspense>
  );
};

export default App;
