import {
  IQuestionKindEnum,
  ISsqQuestionKindEnum,
} from '@inteliam/foundation/lib/enums';

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type {
  IAnswerTextBoxValue,
  IResource,
  ISchemaFieldProps,
  ISsqSchemaFieldProps,
} from '@inteliam/foundation/lib/types';

export function isIAnswerTextBoxValue(
  argument: any
): argument is IAnswerTextBoxValue {
  return typeof argument.format === 'string';
}
export function isIAnswerTextBoxValueOrUndefined(
  argument: any
): argument is IAnswerTextBoxValue | undefined {
  return !argument || typeof argument.format === 'string';
}

export const isArrayOfResources = (value: any): value is IResource[] => {
  return Array.isArray(value) && value.every((resource) => resource.id);
};
export const isInteliamSchemaFieldProps = <T extends IQuestionKindEnum = any>(
  value: any,
  kind?: T
): value is ISchemaFieldProps<T> => {
  if (kind) {
    return value?.field?.kind === kind;
  }
  return [
    IQuestionKindEnum.SingleChoiceField,
    IQuestionKindEnum.MultipleChoiceField,
    IQuestionKindEnum.SingleFreeFormField,
  ].includes(value?.field?.kind as IQuestionKindEnum);
};

export const isSsqSchemaFieldProps = <T extends ISsqQuestionKindEnum = any>(
  value: any,
  kind?: T
): value is ISsqSchemaFieldProps<T> => {
  if (kind) {
    return value?.field?.kind === kind;
  }
  return [
    ISsqQuestionKindEnum.SingleChoiceField,
    ISsqQuestionKindEnum.MultipleChoiceField,
    ISsqQuestionKindEnum.SingleFreeFormField,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  ].includes(value?.field?.kind);
};
export const isChoiceSchemaField = (
  value: any
): value is
  | ISsqSchemaFieldProps<ISsqQuestionKindEnum.SingleChoiceField>
  | ISchemaFieldProps<IQuestionKindEnum.SingleChoiceField>
  | ISsqSchemaFieldProps<ISsqQuestionKindEnum.MultipleChoiceField>
  | ISchemaFieldProps<IQuestionKindEnum.MultipleChoiceField> => {
  return (
    isSingleChoiceSchemaFieldProps(value) ||
    isMultipleChoiceSchemaFieldProps(value)
  );
};
export const isSingleChoiceSchemaFieldProps = (
  value: any
): value is
  | ISsqSchemaFieldProps<ISsqQuestionKindEnum.SingleChoiceField>
  | ISchemaFieldProps<IQuestionKindEnum.SingleChoiceField> => {
  return [
    IQuestionKindEnum.SingleChoiceField,
    ISsqQuestionKindEnum.SingleChoiceField,
  ].includes(value?.field?.kind as IQuestionKindEnum);
};
export const isMultipleChoiceSchemaFieldProps = (
  value: any
): value is
  | ISsqSchemaFieldProps<ISsqQuestionKindEnum.MultipleChoiceField>
  | ISchemaFieldProps<IQuestionKindEnum.MultipleChoiceField> => {
  return [
    IQuestionKindEnum.MultipleChoiceField,
    ISsqQuestionKindEnum.MultipleChoiceField,
  ].includes(value?.field?.kind as IQuestionKindEnum);
};

export const isSingleFreeFormSchemaFieldProps = (
  value: any
): value is
  | ISsqSchemaFieldProps<ISsqQuestionKindEnum.SingleFreeFormField>
  | ISchemaFieldProps<IQuestionKindEnum.SingleFreeFormField> => {
  return [
    IQuestionKindEnum.SingleFreeFormField,
    ISsqQuestionKindEnum.SingleFreeFormField,
  ].includes(value?.field?.kind as IQuestionKindEnum);
};
