import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import { IAPIResponse } from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const logout = async (token: string): Promise<never> => {
  const { data } = await client.instance.get<IAPIResponse<never>>(`logout`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.data;
};
export default {
  logout,
};
