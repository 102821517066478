/* eslint-disable @typescript-eslint/ban-types */
import { ILanguagesEnum, LS_KEYS } from '@inteliam/foundation/lib/enums';
import { useQueryClient } from '@inteliam/foundation/lib/hooks';
import { I18n, LStorage } from '@inteliam/foundation/lib/utils';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FOErrorTracking } from '@core/utils';

import { FullPageSpinner } from '@shared/components';

import { getAuthClientInstance } from '@shared/utils';

import { I18nContext, LocalizerContext, INITIAL_I18_CONTEXT } from './contexts';

const fetcher = (locale: ILanguagesEnum) =>
  import(`../../translations/${locale}.json`).then((module) => module.default);
// import(`../../translations/en_GB.json`); // TODO We need to call this on the first component to prefetch EN the translations

const Localizer: React.FCC = (props) => {
  const [state, setState] = useState<I18nContext>(INITIAL_I18_CONTEXT);
  const queryClient = useQueryClient();
  const [language, setLanguage] = useState<ILanguagesEnum>(
    I18n.getPreferredLocale()
  );

  const setLocale = useCallback(
    async (newLocale: ILanguagesEnum) => {
      const prevLanguage = language;
      setLanguage(newLocale);
      setState((prev) => ({ ...prev, localizingState: 'loading' }));
      try {
        await I18n.setUiLocale(newLocale, fetcher);
        setState((prev) => ({ ...prev, localizingState: 'success' }));
      } catch (error) {
        FOErrorTracking.report(error);
        setState((prev) => ({ ...prev, localizingState: 'error' }));

        // language not found
        setLanguage(prevLanguage);
      }

      // load different package for different language (eg moment locale)
      // importRequiredModulesForLocale(newLocale);
    },
    [language]
  );

  useEffect(() => {
    setLocale(language).catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const changeCurrentLanguage = useCallback(
    async (newLanguage: ILanguagesEnum, doRefresh = true) => {
      try {
        LStorage.set<ILanguagesEnum>(
          LS_KEYS.GLOBAL_USER_LANGUAGE_KEY,
          newLanguage
        );

        if (doRefresh) {
          await getAuthClientInstance().refreshToken();
          setLanguage(newLanguage);
        } else {
          setLanguage(newLanguage);
        }
        // TODO: Maybe this is too much, even queries that are not related to the language are refreshed
        queryClient.resetQueries().catch(() => {});
      } catch (error) {
        FOErrorTracking.report(error);
      }
    },
    [queryClient]
  );
  const context: I18nContext = useMemo(
    () => ({
      ...state,
      setLanguage,
      changeCurrentLanguage,
      language,
    }),
    [state, changeCurrentLanguage, language]
  );

  // TODO : This is only needed for vitest
  if (state.localizingState === 'idle' || state.localizingState === 'loading') {
    return <FullPageSpinner />;
  }

  if (state.localizingState === 'error') {
    // Note Since the error happened in the i18n initialization, we have to show raw text
    // eslint-disable-next-line @inteliam/i18n/raw-text-detected
    return <p>Something went wrong</p>;
  }
  return (
    <LocalizerContext.Provider value={context}>
      {props.children}
    </LocalizerContext.Provider>
  );
};

function useI18n(): I18nContext {
  const context = useContext(LocalizerContext);
  if (context === undefined) {
    throw new Error('useI18n must be used within a LocalizerContext');
  }
  return context;
}

export { useI18n, Localizer as I18nProvider };
