import * as React from 'react';

const Icon: React.FCC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill='none'
      height={props.height || 16}
      width={props.width || 16}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.4512 3.85514L15.1542 6.71657C15.1199 7.03068 14.7346 7.16565 14.5137 6.94479L13.8119 6.24292L9.39211 10.6627C9.11481 10.94 8.62645 10.94 8.34914 10.6627L5.91473 8.22825L1.80175 12.3437C1.65941 12.486 1.47046 12.5597 1.28149 12.5597C1.09253 12.5597 0.903577 12.486 0.761238 12.3437C0.474119 12.0566 0.474119 11.5903 0.761238 11.3007L5.39448 6.66748C5.67179 6.39018 6.16014 6.39018 6.43499 6.66748L8.87186 9.10189L12.7714 5.20241L11.9492 4.38031C11.7259 4.15699 11.8609 3.77171 12.175 3.73981L15.0364 3.44286C15.2745 3.41587 15.4757 3.61711 15.4512 3.85514Z'
        fill='#27AE60'
      />
    </svg>
  );
};

export default Icon;
