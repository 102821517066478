import React from 'react';

import { FancyLoader } from '@shared/components';

const FullPageSpinner: React.FC = () => {
  return (
    <div className='fixed top-0 left-0 bottom-0 right-0 z-[100] flex items-center justify-center bg-secondary'>
      <FancyLoader />
    </div>
  );
};

export default FullPageSpinner;
