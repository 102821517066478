import {
  InteliamReactQueryAdditionalOptions,
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
  InteliamUseQueryOptions,
  InteliamUseQueryResult,
  useQuery as _useQuery,
  useMutation as _useMutation,
} from '@inteliam/foundation/lib/hooks';

import {
  MutationFunction,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query';

import { FOErrorTracking } from '@core/utils';

import { TFunction, useEssentials } from '@core/contexts';

import { ErrorCodesEnumType, ErrorCodesLookup } from '@shared/utils';

import {
  BaseAxiosErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const isErrorCode = (message?: string): boolean => {
  const regexp = new RegExp('^E\\d{4,}$');
  return typeof message === 'string' && regexp.test(message);
};

/**
 * Explanation:
 * 401 (unauthorized) errors: we will redirect the user to the login page -> no need to display an error message
 * 404 (not found) errors: each UI component MUST know how to handle the missing data -> no need to display an error message
 */
const IGNORED_HTTP_STATUS_CODES = new Set([401, 404]);

export const getAxiosErrorMessage = <TError = unknown>(
  t: TFunction,
  error: TError & BaseAxiosErrorResponse
): string => {
  return t(
    ErrorCodesLookup[error.response?.data.message as ErrorCodesEnumType].title,
    { errorCode: error.response?.data.message }
  );
};
export const isBaseAxiosErrorResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg: any
): arg is BaseAxiosErrorResponse => {
  return (
    typeof arg?.response?.data?.message === 'string' &&
    arg?.response?.data?.message
  );
};
const isIApiResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg: any
): arg is IAPIResponse => {
  return typeof arg?.message === 'string';
};

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  key: QueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: InteliamUseQueryOptions<TQueryFnData, TError, TData> &
    InteliamReactQueryAdditionalOptions
): InteliamUseQueryResult<TQueryFnData, TError, TData> {
  const { t, INotifierEnum, notify } = useEssentials();
  return _useQuery(key, queryFn, {
    onError: (error) => {
      if (
        isBaseAxiosErrorResponse(error) &&
        IGNORED_HTTP_STATUS_CODES.has(error.response?.status as number) ===
          false
      ) {
        let translated = '';
        const message = error.response?.data.message;
        if (isErrorCode(message)) {
          translated = getAxiosErrorMessage(t, error);
        } else {
          // Error case and no error code -> report to sentry
          FOErrorTracking.report(
            `A non standardized error has been caught on ${
              error.response?.config.url || 'unknown URL'
            }`
          );
          translated = t(
            'An error has occurred. Please contact inteliam support'
          );
        }
        notify({
          message: translated,
          type: INotifierEnum.Error,
        });
      }
    },
    ...options,
  });
}

export function useMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: InteliamUseMutationOptions<TData, TError, TVariables, TContext> &
    InteliamReactQueryAdditionalOptions
): InteliamUseMutationResult<TData, TError, TVariables, TContext> {
  const { t, INotifierEnum, notify } = useEssentials();

  return _useMutation(mutationFn, {
    onSuccess: (response) => {
      if (isIApiResponse(response))
        notify({
          message: t(response.message || 'Operation successfully completed'),
          type: INotifierEnum.Success,
        });
    },
    onError: (error) => {
      if (isBaseAxiosErrorResponse(error)) {
        notify({
          message: t(
            ErrorCodesLookup[error.response?.data.message as ErrorCodesEnumType]
              .title
          ),
          type: INotifierEnum.Error,
        });
      }
    },
    ...options,
  });
}
