import * as React from 'react';

export interface ISsoContextValue {
  ssoError?: string;
  setSsoError?: React.Dispatch<React.SetStateAction<undefined | string>>;
}
const INITIAL_VALUE: ISsoContextValue = {
  ssoError: undefined,
  setSsoError: undefined,
};

interface Props {
  children: React.ReactNode;
  value: ISsoContextValue;
}

export const SsoContext = React.createContext<ISsoContextValue>(INITIAL_VALUE);

const SsoContextProvider: React.FCC<Props> = ({ children, value }) => {
  return (
    <SsoContext.Provider
      value={{ ssoError: value.ssoError, setSsoError: value.setSsoError }}
    >
      {children}
    </SsoContext.Provider>
  );
};

export default SsoContextProvider;
