import clsx from 'clsx';

import * as React from 'react';

import {
  CheckMarkIcon,
  ErrorIcon,
  WarningIcon,
  HelpIcon,
} from '@shared/components/icons';

type Props = {
  variant: 'success' | 'error' | 'info' | 'warning';
  message: string;
  dismissable?: boolean;
  onDismiss?: () => void;
};

const ICON_MAP = {
  info: <HelpIcon width={15} height={15} />,
  success: <CheckMarkIcon width={15} height={15} />,
  error: <ErrorIcon width={15} height={15} />,
  warning: <WarningIcon width={15} height={15} />,
};

const SnackBar: React.FC<Props> = ({
  variant,
  message,
  dismissable = true,
  onDismiss,
}) => {
  return (
    <div
      className={clsx(
        'whitespace-no-wrap mx-2 flex max-w-sm flex-row items-center justify-between space-x-4 rounded-xl p-3 text-sm font-medium leading-none shadow-lg sm:mx-auto',
        {
          'bg-success': variant === 'success',
          'bg-error': variant === 'error',
          'bg-info': variant === 'info',
          'bg-warning': variant === 'warning',
        }
      )}
    >
      <div
        className={clsx('inline-flex items-center space-x-3', {
          'text-info': variant === 'info',
          'text-success': variant === 'success',
          'text-error': variant === 'error',
          'text-warning dark:text-orange-300': variant === 'warning',
        })}
      >
        <span className='flex'>{ICON_MAP[variant]}</span>
        <span>{message}</span>
      </div>
      <div
        className={clsx('cursor-pointer', {
          'text-blue-700 hover:text-blue-800': variant === 'info',
          'text-green-700 hover:text-green-800': variant === 'success',
          'text-red-700 hover:text-red-800': variant === 'error',
          'text-yellow-700 hover:text-yellow-800': variant === 'warning',
        })}
      >
        <span
          className={clsx(
            'item-center inline-flex flex-shrink-0 justify-center rounded-full border-l-2 border-t-2 p-1 leading-none',
            {
              'border-blue-700': variant === 'info',
              'border-green-700': variant === 'success',
              'border-red-700': variant === 'error',
              'border-yellow-700': variant === 'warning',
            }
          )}
        >
          {dismissable && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-4 w-4'
              viewBox='0 0 20 20'
              fill='currentColor'
              onClick={onDismiss}
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          )}
        </span>
      </div>
    </div>
  );
};

export default SnackBar;
