import * as React from 'react';

const Icon: React.FCC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='48'
      height='35'
      viewBox='0 0 48 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.4349 34.4564C15.6523 34.4564 14.9349 34.1955 14.348 33.6086L1.36969 20.6303C0.19578 19.4564 0.19578 17.6303 1.36969 16.4564C2.54361 15.2825 4.36969 15.2825 5.54361 16.4564L16.5001 27.3477L42.5219 1.39118C43.6958 0.217264 45.5219 0.217264 46.6958 1.39118C47.8697 2.56509 47.8697 4.39118 46.6958 5.56509L18.5871 33.6086C17.9349 34.1955 17.2175 34.4564 16.4349 34.4564Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Icon;
