import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Client, ResponseType } from '@microsoft/microsoft-graph-client';

import { useMsal } from '@azure/msal-react';
import { useContext, useEffect, useState } from 'react';

import { SsoContext, useEssentials } from '@core/contexts';

import { IMicrosoftProfile } from '@shared/types';

const getGraphApiClient = (accessToken: string) => {
  return Client.init({
    authProvider: (done) => {
      done(undefined, accessToken);
    },
  });
};

const useMsftSSO = () => {
  const { exchangeSsoToken } = useEssentials();
  const { setSsoError } = useContext(SsoContext);
  const { instance, accounts, inProgress } = useMsal();

  const [profile, setProfile] = useState<IMicrosoftProfile | undefined>(
    undefined
  );

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ['user.read', 'profile', 'email'],
      account: accounts[0],
    };
    if (!profile && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (response: AuthenticationResult) => {
          const meResponse = await getGraphApiClient(response.accessToken)
            .api('/me')
            .responseType(ResponseType.JSON)
            .get();
          const orgResponse = await getGraphApiClient(response.accessToken)
            .api('/organization')
            .responseType(ResponseType.JSON)
            .get();
          setProfile({
            firstName: meResponse.givenName as string,
            lastName: meResponse.surname as string,
            position: meResponse.jobTitle as string,
            preferredLanguage: meResponse.preferredLanguage as string,
            email: meResponse.mail as string,
            company: orgResponse.value[0].displayName as string,
            tenantId: orgResponse.value[0].id as string,
          });

          await exchangeSsoToken('microsoft', response.idToken, {
            firstName: meResponse.givenName,
            lastName: meResponse.surname,
            position: meResponse.jobTitle,
            preferredLanguage: meResponse.preferredLanguage,
            email: meResponse.mail,
            company: orgResponse.value[0].displayName,
            tenantId: orgResponse.value[0].id,
          } as IMicrosoftProfile);
        })
        .catch((error) => {
          if (setSsoError !== undefined) {
            setSsoError(error.response?.data?.message as string);
          }
          instance
            .logoutRedirect({
              onRedirectNavigate: (_url) => {
                return false; // Return false if you would like to stop navigation after local logout
              },
            })
            .catch(() => {});
        });
    }
  }, [instance, accounts, inProgress, profile, setSsoError, exchangeSsoToken]);

  const getMicrosoftProfile = () => {
    return profile;
  };
  const getInstance = () => {
    return instance;
  };

  return { getMicrosoftProfile, getInstance };
};

export default useMsftSSO;
