import * as React from 'react';

import { AlertType } from './notifier-context';

interface ConfirmOptions {
  type: AlertType | 'default';
  title?: string;
  description?: React.ReactNode;
  customButtons?: (args: {
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
  }) => React.ReactNode;
  style?: React.CSSProperties;
  onOpen?: (onClose: () => void) => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
  showCloseButton?: boolean;
  closeOnClickOutside?: boolean;
}
export const CONFIRM_CONTEXT_DEFAULT_OPTIONS: ConfirmOptions & {
  title: string;
  type: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  showCancel: boolean;
} = {
  type: 'default',
  title: 'Are you sure ?',
  confirmBtnText: undefined,
  cancelBtnText: undefined,
  showCancel: true,
  showConfirm: true,
  showCloseButton: false,
  closeOnClickOutside: true,
};

export type IConfirmerDispatcher = (
  options: ConfirmOptions
) => Promise<unknown>;

const ConfirmerContext = React.createContext<IConfirmerDispatcher>(() =>
  Promise.resolve()
);

export { ConfirmerContext };
