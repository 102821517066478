import { ILanguagesEnum, LS_KEYS } from '@inteliam/foundation/lib/enums';
import { assertIsTyped, isIFOUser } from '@inteliam/foundation/lib/guards';
import { useQuery, useQueryClient } from '@inteliam/foundation/lib/hooks';
import {
  AuthHelpers,
  LStorage,
  QueryString,
} from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { QUERY_KEYS } from '@core/queries';

import { FullPageSpinner } from '@shared/components';

import { getAuthClientInstance } from '@shared/utils';

import type {
  BaseAxiosErrorResponse,
  ISsoProfile,
  JwtFOUser,
} from '@inteliam/foundation/lib/types';

import { IAuthenticatedUserContext } from './auth-context';
import { AuthContext, IUserContext } from './contexts';

type UserResponseType = Parameters<typeof AuthHelpers.handleUserResponse>[0];

const useAuthHandlers = () => {
  const impersonateToken = QueryString.getQueryString('impersonate') as
    | string
    | undefined;

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const connectedUserQuery = useQuery<
    JwtFOUser | undefined,
    BaseAxiosErrorResponse
  >(
    QUERY_KEYS.CONNECTED_USER,
    () => {
      if (impersonateToken) {
        AuthHelpers.handleUserResponse({
          data: {
            access_token: impersonateToken,
            impersonating: true,
            refresh_token: '',
          },
        } as UserResponseType);
      }
      return getAuthClientInstance().getUser();
    },
    {
      enabled: true,
      retry: false,
      cacheTime: 0,
      onSettled: (data, error) => {
        if (error || !data) {
          AuthHelpers.removeAccessToken();
        } else {
          // Success
          if (impersonateToken) {
            LStorage.set<ILanguagesEnum>(
              LS_KEYS.GLOBAL_USER_LANGUAGE_KEY,
              data.preferredLocale as ILanguagesEnum
            );
            navigate('/', { replace: true });
          }
        }
      },
    }
  );

  const onSetUser: IUserContext['setUser'] = React.useCallback(
    (user) => {
      if (isNil(user)) {
        // eslint-disable-next-line unicorn/no-null
        queryClient.setQueryData(QUERY_KEYS.CONNECTED_USER, null);
      } else {
        queryClient.setQueryData(QUERY_KEYS.CONNECTED_USER, user);
      }
    },
    [queryClient]
  );
  const handlers: Omit<IUserContext, 'user'> = React.useMemo(
    () => ({
      login: async (form) => {
        const newUserContext = await getAuthClientInstance().login(form);

        onSetUser(newUserContext);
        return newUserContext;
      },
      logout: () => {
        onSetUser(undefined);
        LStorage.remove(LS_KEYS.GLOBAL_USER_LANGUAGE_KEY);
        return AuthHelpers.logout();
      },
      resetPassword: getAuthClientInstance().resetPassword,
      forgetPassword: getAuthClientInstance().forgetPassword,
      exchangeSsoToken: async (
        provider: 'microsoft',
        accessToken: string,
        profileData: ISsoProfile
      ) => {
        const newUserContext = await getAuthClientInstance().exchangeSsoToken(
          provider,
          accessToken,
          profileData
        );
        onSetUser(newUserContext);
        return newUserContext;
      },
      setUser: onSetUser,
    }),
    [onSetUser]
  );

  return { connectedUserQuery, handlers };
};

const AuthProvider: React.FCC = (props) => {
  const { connectedUserQuery, handlers } = useAuthHandlers();
  const context = React.useMemo(
    () => ({
      user: connectedUserQuery.data,
      ...handlers,
    }),
    [connectedUserQuery.data, handlers]
  );

  if (!connectedUserQuery.isSettled) {
    return <FullPageSpinner />;
  }

  return <AuthContext.Provider value={context} {...props} />;
};

function useAuth(useGuard?: boolean): IAuthenticatedUserContext;
function useAuth(useGuard?: undefined): IUserContext;
function useAuth(
  useGuard?: boolean | undefined
): IAuthenticatedUserContext | IUserContext {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  const user = context.user;
  if (useGuard) {
    assertIsTyped(user, isIFOUser);
    return { ...context, user };
  }
  return context;
}

export { AuthProvider, useAuth };
