import * as React from 'react';

import { SnackBar } from '@shared/components';

import {
  AlertType,
  INotifierDispatcher,
  INotifierEnum,
  INotifierState,
  NotifierContext,
} from './notifier-context';

const TIMEOUT = 6000;

interface AlertMapping {
  [key: string]: AlertType;
}

export const ALERT_MAPPING: AlertMapping = {
  idle: INotifierEnum.Info,
  loading: INotifierEnum.Info,
  success: INotifierEnum.Success,
  error: INotifierEnum.Error,
};

const NotifierProvider: React.FCC = ({ children, ...rest }) => {
  const [state, setState] = React.useState<
    Array<INotifierState & { timeout: NodeJS.Timeout }>
  >([]);

  const notify = React.useCallback((notification: INotifierState) => {
    // Schedule the dismissal of the notification
    const timeout = setTimeout(() => {
      setState((prevState) => prevState.filter((_, index) => index !== 0));
    }, TIMEOUT);
    // Show the notification
    setState((prevState) => [...prevState, { ...notification, timeout }]);
  }, []);

  return (
    <NotifierContext.Provider value={notify} {...rest}>
      {children}
      {state.length > 0 &&
        state.map((notification, index) => (
          <div
            key={index}
            className={'fixed top-5 right-5 z-[100]'}
            style={{
              marginTop: 60 * index,
            }}
          >
            <SnackBar
              variant={notification.type || INotifierEnum.Info}
              message={notification.message}
              dismissable
              onDismiss={() => {
                clearTimeout(notification.timeout);
                setState((prevState) => {
                  return prevState.filter(
                    (_notification) =>
                      _notification.timeout !== notification.timeout
                  );
                });
              }}
            />
          </div>
        ))}
    </NotifierContext.Provider>
  );
};

function useNotifier(): INotifierDispatcher {
  const context = React.useContext(NotifierContext);
  if (context === undefined) {
    throw new Error('useNotifier must be used within a NotifierProvider');
  }
  return context;
}

export { NotifierProvider, useNotifier };
