import clsx from 'clsx';

import * as React from 'react';

// import './style.scss';
export const ButtonSizeEnum = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
} as const;
type ButtonSizeOptions = typeof ButtonSizeEnum[keyof typeof ButtonSizeEnum];

const SIZES: Record<
  ButtonSizeOptions,
  {
    padding: string | number;
  }
> = {
  xs: {
    padding: '0.25rem 0.5rem',
  },
  sm: {
    padding: '0.25rem 0.5rem',
  },
  md: {
    padding: '0.4rem 0.75rem',
  },
  lg: {
    padding: '0.5rem 1rem',
  },
};

export const ButtonVariantEnum = {
  inteliam: 'inteliam',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  neutral: 'neutral',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'info',
  transparent: 'transparent',
  link: 'link',
} as const;

type ButtonVariantOptions =
  typeof ButtonVariantEnum[keyof typeof ButtonVariantEnum];

const __DEFAULT_ELEMENT__ = 'button' as const;
type ButtonOwnProps<E extends React.ElementType = typeof __DEFAULT_ELEMENT__> =
  {
    component?: E;
    id: string;
    outline?: boolean;
    size?: ButtonSizeOptions;
    variant?: ButtonVariantOptions;
    disabled?: boolean;
    isLoading?: boolean;
  };

type ButtonProps<E extends React.ElementType> = ButtonOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof ButtonOwnProps>;

// eslint-disable-next-line sonarjs/cognitive-complexity
function InnerButton<T extends React.ElementType = typeof __DEFAULT_ELEMENT__>(
  {
    component: Component,
    outline,
    size = 'md',
    variant = 'inteliam',
    disabled,
    isLoading,
    ...props
  }: ButtonProps<T>,
  ref: React.Ref<HTMLButtonElement>
): React.ReactElement {
  const ComponentToUse = Component || __DEFAULT_ELEMENT__;
  const isDisabled = disabled || isLoading;
  return (
    <ComponentToUse
      ref={ref}
      type='button'
      {...props}
      disabled={isDisabled}
      className={clsx(
        'btn ',
        {
          'btn-inteliam': variant === 'inteliam' && !outline,
          'border-1 border-solid border-inteliam bg-secondary text-inteliam hover:bg-inteliam hover:text-white':
            variant === 'inteliam' && outline,
          'bg-inteliam text-white hover:bg-green-700': variant === 'primary',
          'bg-secondary': variant === 'secondary',
          'bg-tertiary': variant === 'tertiary',
          'bg-success text-success': variant === 'success' && !outline,
          'border-success bg-transparent text-success hover:bg-success':
            variant === 'success' && outline,
          'bg-error text-error': variant === 'danger' && !outline,
          'border-error bg-transparent text-error hover:bg-error':
            variant === 'danger' && outline,
          'bg-warning text-warning': variant === 'warning' && !outline,
          'border-warning bg-transparent text-warning hover:bg-warning':
            variant === 'warning' && outline,
          'bg-info text-info': variant === 'info' && !outline,
          'border-1 border-solid border-info bg-transparent text-info hover:bg-ongoing hover:text-white ':
            variant === 'info' && outline,
          'border-none bg-transparent text-primary outline-none hover:bg-gray-200':
            variant === 'transparent' && !outline,
          'border border-solid border-gray-300 bg-transparent text-primary':
            variant === 'transparent' && outline,
          'bg-transparent text-primary': variant === 'neutral' && !outline,
          'border-1 border-solid  border-gray-200 bg-transparent text-primary hover:bg-[#495050] hover:text-white':
            variant === 'neutral' && outline,
          'border-none bg-transparent text-primary outline-none hover:underline hover:shadow-none':
            variant === 'link',
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-base': size === 'md',
          'text-lg': size === 'lg',
          'pointer-events-none opacity-50': isDisabled,
        },
        props.className
      )}
      style={size ? { ...props.style, ...SIZES[size] } : { ...props.style }}
      data-cy={props.id}
    />
  );
}

export default React.forwardRef(InnerButton);
