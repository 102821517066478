import * as React from 'react';

import './style.css';

const FancyLoader: React.FCC<{ variant?: 'inteliam' | 'white' }> = ({
  variant,
}) => {
  const variantClass = variant === 'white' ? 'bg-secondary' : 'bg-inteliam';

  return (
    <>
      <div className='loader'>
        <div className='loader-inner line-scale'>
          <div className={variantClass}></div>
          <div className={variantClass}></div>
          <div className={variantClass}></div>
          <div className={variantClass}></div>
          <div className={variantClass}></div>
        </div>
      </div>
    </>
  );
};

export default FancyLoader;
