/* eslint-disable @inteliam/i18n/raw-text-detected */
import * as React from 'react';

import { Box, BuoyIcon } from '@shared/components';

import './style.scss';

//Import Images

const Pages500: React.FCC = ({ children }) => {
  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-5'>
        <Box>
          <Box className='flex items-center justify-center'>
            <div className='mb-5 text-center'>
              <h1 className='flex items-center justify-center text-xl'>
                5<BuoyIcon className='spin display-3 text-inteliam' />0
              </h1>
              <h4 className='text-uppercase'>Internal Server Error</h4>
              <div className='mt-5 text-center'>
                {children}
                <a
                  id='go-dashboard'
                  className='btn btn-inteliam rounded-md p-2 text-sm'
                  href='/dashboard'
                >
                  Back to Dashboard
                </a>
              </div>
            </div>
          </Box>
          <Box className='flex h-64 w-96 items-center justify-center'>
            <img
              src={'/images/error-img.png'}
              alt=''
              className='h-auto max-w-full'
            />
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Pages500;
