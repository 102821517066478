import { IDistributorRolesEnum } from '@inteliam/foundation/lib/enums';

import { IOptionItemAdvanced, TranslationKeys } from '@shared/types';

type IDistributorRolePreset = {
  [key in IDistributorRolesEnum]: {
    value: IDistributorRolesEnum;
    label: TranslationKeys;
    tag: 'DISTRIBUTOR_STANDARD' | 'DISTRIBUTOR_LIMITED';
  };
};
export const RB_DISTRIBUTOR_ROLES: IDistributorRolePreset = {
  [IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD]: {
    value: IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD,
    label: 'Standard',
    tag: 'DISTRIBUTOR_STANDARD',
  },
  [IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED]: {
    value: IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED,
    label: 'Limited',
    tag: 'DISTRIBUTOR_LIMITED',
  },
};

export const DISTRIBUTOR_ROLES: IOptionItemAdvanced[] = [
  {
    ...RB_DISTRIBUTOR_ROLES.ROLE_DISTRIBUTOR_LIMITED,
    helperText: 'Limited access (financial metrics theme not accessible)',
    label: 'Limited',
  },
  {
    ...RB_DISTRIBUTOR_ROLES.ROLE_DISTRIBUTOR_STANDARD,
    helperText:
      "Full access (all themes, documents, standard's functionalities such as invite colleagues, user management)",
    label: 'Standard',
  },
];
