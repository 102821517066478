import { ILanguagesEnum } from '@inteliam/foundation/lib/enums';
import { I18n } from '@inteliam/foundation/lib/utils';

import * as I18Next from 'i18next';

import * as React from 'react';

import { FOErrorTracking } from '@core/utils';

import { TranslationKeys } from '@shared/types';

import {
  Dispatcher,
  IForgetPasswordPayload,
  ILoginPayload,
  IResetPasswordPayload,
  JwtFOUser,
  ISsoProfile,
} from '@inteliam/foundation/lib/types';

export interface TFunction {
  // basic usage
  <
    TKeys extends TranslationKeys,
    // eslint-disable-next-line @typescript-eslint/ban-types
    TInterpolationMap extends object = I18Next.StringMap
  >(
    key: TKeys | TKeys[],
    options?: I18Next.TOptions<TInterpolationMap> | string
  ): string;
  // overloaded usage
  <
    TKeys extends I18Next.TFunctionKeys = string,
    TInterpolationMap extends object = I18Next.StringMap
  >(
    key: TKeys | TKeys[],
    defaultValue?: string,
    options?: I18Next.TOptions<TInterpolationMap> | string
  ): string;
}
export interface I18nContext {
  language: ILanguagesEnum;
  t: TFunction;
  localizingState: 'idle' | 'loading' | 'success' | 'error';
  changeCurrentLanguage: (
    language: ILanguagesEnum,
    doRefresh?: boolean
  ) => void;
}

export const INITIAL_I18_CONTEXT: I18nContext = {
  language: I18n.getPreferredLocale(),
  t: (
    key: I18Next.TFunctionKeys | I18Next.TFunctionKeys[],
    options?: I18Next.TOptions<I18Next.StringMap> | string
  ) => {
    // we don't need to throw when using default lang (en_GB), since translation for that language are hardcoded
    if (
      I18n.ReactI18NextInstance.getI18n().language !== I18n.defaultLocale &&
      !I18n.ReactI18NextInstance.getI18n().exists(key as string)
    ) {
      FOErrorTracking.report(
        new Error(`
        Missing translation key: ${JSON.stringify(key)} for ${
          I18n.ReactI18NextInstance.getI18n().language
        }
      `)
      );
    }
    return I18n.I18NextInstance.default.t(key, options);
  },
  changeCurrentLanguage: () => {},
  localizingState: 'idle',
};

export const LocalizerContext =
  React.createContext<I18nContext>(INITIAL_I18_CONTEXT);

// Auth
export interface IUserContext {
  user: JwtFOUser | undefined;
  login: (input: ILoginPayload) => Promise<JwtFOUser | undefined>;
  exchangeSsoToken: (
    provider: 'microsoft',
    accessToken: string,
    profileData: ISsoProfile
  ) => Promise<JwtFOUser | undefined>;
  logout: () => Promise<void>;
  resetPassword: (form: IResetPasswordPayload) => Promise<void>;
  forgetPassword: (form: IForgetPasswordPayload) => Promise<void>;
  setUser: Dispatcher<JwtFOUser | undefined>;
}

const INITIAL_AUTH_CONTEXT: IUserContext = {
  user: undefined,
  login: () => Promise.resolve(undefined),
  exchangeSsoToken: () => Promise.resolve(undefined),
  logout: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  forgetPassword: () => Promise.resolve(),
  setUser: () => {},
};

export const AuthContext =
  React.createContext<IUserContext>(INITIAL_AUTH_CONTEXT);
