import { AuthHelpers, Helpers } from '@inteliam/foundation/lib/utils';

export const CONNECTED_USER = [
  'connected-user',
  AuthHelpers.getAccessToken() || Helpers.uuid(),
];
export const SURVEY = [...CONNECTED_USER, 'survey'];
export const SURVEY_PROGRESS = [...CONNECTED_USER, ...SURVEY, 'progress'];
export const SURVEY_CLASSIFICATION = [...SURVEY, 'classification'];
export const SURVEY_LANDING = [...SURVEY, 'landing'];
export const SURVEY_CRITERIA = [...SURVEY, 'criteria'];

export const THEMES = [...CONNECTED_USER, 'themes'];

export const SHARING_REQUESTS = [...CONNECTED_USER, 'sharing-requests'];
export const TOP_SUPPLIERS = [...SHARING_REQUESTS, 'top3-features'];
export const ASSESSMENT_REQUEST = [...CONNECTED_USER, 'assessment-requests'];

export const AR_PROGRESS = [...ASSESSMENT_REQUEST, 'progress-widget'];
export const AR_IN_SURVEY = ['in-survey-ar'];
export const AR_IN_PROGRESS = ['in-progress-assessment-request'];
// FOD
export const DOCUMENTS = [...SURVEY, 'documents'];
export const DOCUMENT_TYPES = [...DOCUMENTS, 'types'];
export const DOCUMENT_VISIBILITIES = [...DOCUMENTS, 'visibilities'];
export const DOCUMENT_VALIDATION = [...DOCUMENTS, 'validation'];
export const LANGUAGES_LIST = [...CONNECTED_USER, 'languages'];
export const AVAILABLE_LANGUAGES = [...CONNECTED_USER, 'available-languages'];

// TODO rework all keys strategy
export const REFERENCE = [...DOCUMENTS, 'reference'];
export const ONE_REFERENCE = [...REFERENCE, 'one'];
export const MANY_REFERENCES = [...REFERENCE, 'many'];

export const CURRENCIES = [...CONNECTED_USER, 'currencies'];
export const BEST_PRACTICES = [...CONNECTED_USER, 'best-practices'];

export const DISTRIBUTORS = [...CONNECTED_USER, 'distributors'];
export const MANUFACTURERS = [...CONNECTED_USER, 'manufacturers'];
export const DISTRIBUTOR_PROFILE = [...DISTRIBUTORS, 'profile'];

export const NEWS = [...CONNECTED_USER, 'news'];

export const MANAGERS = [...DISTRIBUTORS, 'managers-users'];
export const DISTRIBUTOR_USERS = [...DISTRIBUTORS, 'users'];

export const SCORE_CARD = [...CONNECTED_USER, 'score-card'];
export const SCORE_CARD_HISTORY = [...CONNECTED_USER, 'score-card-history'];
export const SCORE_CARD_TRENDLINE = [...CONNECTED_USER, 'score-card-trendline'];
export const PROFILE_VIEWERS = [...CONNECTED_USER, 'profile-viewers'];
export const SCORE_CARD_REVIEWS = [...CONNECTED_USER, 'score-card-reviews'];
export const BENCHMARK = [...SCORE_CARD, 'benchmark'];
export const RADAR_DATA_SET = [...BENCHMARK, 'radar'];
export const DISTRIBUTION_GRAPH = [...BENCHMARK, 'distribution'];
export const COUNTRIES = [...CONNECTED_USER, 'countries'];
export const BENCHMARK_COUNTRIES = [...BENCHMARK, 'countries'];
export const BENCHMARK_COUNTRIES_CLASSIFICATION = [
  ...BENCHMARK,
  'classification',
];

export const PORTFOLIO = ['portfolio'];
export const PORTFOLIO_TABLE = ['portfolio-table'];
export const FAVORITES = [...CONNECTED_USER, 'favorites'];

export const FOM = [...CONNECTED_USER, 'fom'];
export const FOM_DASHBOARD = [...FOM, 'dashboard'];
export const FOM_ANALYSIS_DASHBOARD_GRAPH = [...FOM_DASHBOARD, 'graph'];
export const FOM_ANALYSIS_MAP = [...FOM_DASHBOARD, 'map'];
export const FOM_ANALYSIS_DASHBOARD_ALERT_WALL = [
  ...FOM_DASHBOARD,
  'alert-wall',
];

export const FOM_PERFORMANCE_DASHBOARD_SIA = [...FOM, 'performance-sia'];
export const FOM_PERFORMANCE_DASHBOARD_DISTRIBUTION_GRAPH = [
  ...FOM,
  'performance-graph',
];

export const DISTRIBUTORS_DIRECTORY = ['directory'];

export const FOM_SHARED_DOCUMENTS = [...FOM, 'shared-documents'];

export const PRICING_GRID = ['pricing-grid'];
export const USER = ['user'];

export const COMPANY_QUALIFICATION_ENUMS = ['company-enums'];

export const SSQ_MANUFACTURERS_LIST = [...CONNECTED_USER, 'ssq.manufacturers'];
export const SSQ_QUESTIONNAIRE_LIST = ['ssq.questionnaires'];
export const SSQ_QUESTIONNAIRE_PROGRESS = [
  ...SSQ_QUESTIONNAIRE_LIST,
  'progress',
];
export const SSQ_QUESTIONNAIRE_ANSWERS = [
  ...SSQ_QUESTIONNAIRE_LIST,
  'ssq.answers',
];

export const ASSESSMENT_HIGHLIGHTS = [
  ...CONNECTED_USER,
  'assessment-highlights',
];

export const CARBON_EMISSION_BY_SOURCE = [
  ...CONNECTED_USER,
  'ghg.emissions-by-source',
];
export const CARBON_OVERVIEW = [...CONNECTED_USER, 'ghg.overview'];
export const CARBON_QUESTIONNAIRE_REQUEST = [...CONNECTED_USER, 'ghg.request'];
export const CARBON_QUESTIONNAIRE_LAUNCH = [...CONNECTED_USER, 'ghg.launch'];
export const CARBON_QUESTIONNAIRE_ANSWERS = [...CONNECTED_USER, 'ghg.answers'];
export const CARBON_QUESTIONNAIRE_LAST_CO2_AR = [
  ...CONNECTED_USER,
  'ghg.last_co2_ar',
];
export const CARBON_DECLARATION_YEARS = [
  ...CONNECTED_USER,
  'ghg.declaration-years',
];
export const SUSTAINABILITY_CARDS = [...CONNECTED_USER, 'sustainability-cards'];

export const GLOBAL_SCORE_DISTRIBUTION_BY_REGION = [
  ...CONNECTED_USER,
  'global-score.by-region',
];

export const GLOBAL_SCORE_DISTRIBUTION = [
  ...CONNECTED_USER,
  'global-score-distribution',
];
export const CRITERIA_AVG_SCORES_BY_THEME = [
  ...CONNECTED_USER,
  'criteria-avg-scores-by-theme',
];
