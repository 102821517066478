import * as React from 'react';

const HelpIcon: React.FCC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={17}
      height={17}
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.53 12.897a.608.608 0 01-.382-.139.587.587 0 01-.209-.591l.852-4.348-.47.26a.62.62 0 01-.834-.225.62.62 0 01.226-.835l1.617-.922a.607.607 0 01.661.035c.192.14.279.383.244.609l-.887 4.521.73-.313c.313-.139.661 0 .8.313.14.313 0 .661-.313.8l-1.791.783a.555.555 0 01-.244.052zm1.722-8.313a.886.886 0 00.261-.608.816.816 0 00-.26-.61.886.886 0 00-.61-.26.816.816 0 00-.608.26.886.886 0 00-.261.61c0 .226.087.452.26.608.157.157.383.261.61.261a.816.816 0 00.608-.26zM16 8.063a8.001 8.001 0 00-16 0 8.001 8.001 0 0016 0zm-1.391 0A6.606 6.606 0 018 14.67a6.606 6.606 0 01-6.609-6.608A6.606 6.606 0 018 1.452a6.606 6.606 0 016.609 6.61z'
        fill={'currentColor'}
      />
    </svg>
  );
};

export default HelpIcon;
