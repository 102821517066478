/* eslint-disable unused-imports/no-unused-imports */
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';


import { AppProviders } from '@core/contexts';
import Router from '@core/routers';
import './core/assets/scss/app.scss'; // theme entry point
import './index.scss'; // tailwind entry point

// Prefetch english translations
// @ts-expect-error shut up this is a good thing
import englishTranslations from './translations/en_GB.json';

const strict = false;


let Container = React.Fragment;
if (strict) {
  Container = React.StrictMode;
}
const rootContainer = document.getElementById('root');
if (!rootContainer) throw new Error('container not found!');

const root = ReactDOM.createRoot(rootContainer);

const renderDevelopment = () => {
  import('./wdyr').then(() => {
    renderProduction()
  }).catch(() => {})
}

const renderProduction = () => {
  root.render(<Container>
    <AppProviders>
      <Router />
    </AppProviders>
  </Container>);
}

if (import.meta.env.DEV) {
  renderDevelopment()
} else {
  renderProduction()
}
