import * as React from 'react';

export const INotifierEnum = {
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
} as const;
export type INotifierEnumType = typeof INotifierEnum;
export type AlertType = typeof INotifierEnum[keyof INotifierEnumType];

export interface INotifierState {
  // position?: SnackbarOrigin; // TODO: implement this
  message: string;
  type?: AlertType;
}

export type INotifierDispatcher = (notif: INotifierState) => void;

const NotifierContext = React.createContext<INotifierDispatcher>(() => {});

export { NotifierContext };
