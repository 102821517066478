import * as React from 'react';

import {
  useAuth,
  useI18n,
  I18nContext,
  useConfig,
  useNotifier,
  INotifierEnum,
  INotifierEnumType,
  useConfirm,
} from '@core/contexts';

import type { IAuthenticatedUserContext } from './auth-context';

export type Sites = 'FOM' | 'FOD' | 'UNKNOWN';
type SiteDetector = () => Sites;
type IEssentials = I18nContext &
  IAuthenticatedUserContext & {
    getSite: SiteDetector;
    notify: ReturnType<typeof useNotifier>;
    confirm: ReturnType<typeof useConfirm>;
    config: ReturnType<typeof useConfig>;
    INotifierEnum: INotifierEnumType;
    na: string;
  };
const useEssentials = (): IEssentials => {
  const authContext = useAuth();
  const i18nContext = useI18n();
  const notify = useNotifier();
  const confirm = useConfirm();
  const config = useConfig();

  const getSite: SiteDetector = React.useCallback(() => {
    switch (authContext.user?.kind) {
      case 'DISTRIBUTOR': {
        return 'FOD';
      }
      case 'SUPPLIER':
      case 'TRADING_GROUP': {
        return 'FOM';
      }
      default: {
        return 'UNKNOWN';
      }
    }
  }, [authContext.user?.kind]);

  const na = i18nContext.t('N/A');
  return {
    ...authContext,
    ...i18nContext,
    confirm,
    config,
    na,
    notify,
    INotifierEnum,
    getSite,
  };
};

export default useEssentials;
