import * as React from 'react';

const ErrorIcon: React.FCC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={50}
      height={50}
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 0C11.215 0 0 11.215 0 25s11.215 25 25 25 25-11.215 25-25S38.785 0 25 0zM5.001 25c0-4.616 1.585-8.855 4.22-12.243L37.244 40.78C33.859 43.413 29.616 45 25 45c-11.027 0-20-8.973-20-20zM40.78 37.242L12.757 9.221C16.145 6.586 20.386 5 25 5c11.028 0 20 8.972 20 20 0 4.615-1.587 8.855-4.22 12.242z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ErrorIcon;
