import {
  AnswerValueFormatEnum,
  IQuestionKindEnum,
  ISsqQuestionKindEnum,
} from '@inteliam/foundation/lib/enums';

import {
  isArrayOfResources,
  isIAnswerTextBoxValue,
  isIAnswerTextBoxValueOrUndefined,
} from '@core/guards';
import {
  CommonFieldNormalizerLookup,
  CommonFieldTypeLookup,
  IFormDataNormalizer,
  INavigationQuestion,
  IFieldFormData,
  IPayloadNormalizer,
  IPrepareInput,
  ISurveyProgress,
  SurveyClientFieldName,
} from '@core/types';

import type {
  IAnswerTextBoxValue,
  IOptionProperties,
} from '@inteliam/foundation/lib/types';

const getTextBoxValue = (
  textbox: IAnswerTextBoxValue | undefined
): IAnswerTextBoxValue | undefined => {
  if (!textbox) {
    return undefined;
  }

  if (textbox && textbox.format === AnswerValueFormatEnum.FORMAT_NUMERICAL) {
    const getNumericalValue = () => {
      if (typeof textbox.value === 'string' && textbox.value !== '')
        return Number.parseFloat(textbox.value);
      else if (textbox.value === '') return undefined;
      return textbox.value;
    };
    return {
      ...textbox,
      value: getNumericalValue(),
    };
  }
  if (textbox && textbox.format === AnswerValueFormatEnum.FORMAT_MONETARY) {
    return {
      ...textbox,
      amount:
        typeof textbox.amount === 'string'
          ? Number.parseFloat(textbox.amount)
          : textbox.amount,
    };
  }
  return textbox;
};

export const getResetSelectedOption = ({
  currentData = {
    options: {},
  },
  options = [],
}: {
  currentData?: IFieldFormData;
  options?: Array<IOptionProperties>;
}): string | undefined => {
  return options
    .filter((option) => currentData.options[option.value]?.field === true)
    .find((option) => option.schema.option?.reset === true)?.value;
};

const common = {
  prepare:
    ({ currentData, optionId, value }: IPrepareInput) =>
    (fieldName: SurveyClientFieldName): IFieldFormData => {
      return {
        ...currentData,
        // @ts-expect-error - we are using the fieldName as a key
        options: {
          ...currentData.options,
          [optionId]: {
            // field: true,
            ...currentData.options[optionId],
            [fieldName]:
              fieldName === 'textbox' && isIAnswerTextBoxValueOrUndefined(value)
                ? getTextBoxValue(value)
                : value,
          },
        },
      };
    },
};

export const COMMON_FIELD_TYPE_LOOKUP: CommonFieldTypeLookup = {
  [IQuestionKindEnum.SingleFreeFormField]: 'SINGLE_FREEFORM',
  [IQuestionKindEnum.SingleChoiceField]: 'SINGLE_CHOICE',
  [IQuestionKindEnum.MultipleChoiceField]: 'MULTIPLE_CHOICE',
  [ISsqQuestionKindEnum.SingleFreeFormField]: 'SINGLE_FREEFORM',
  [ISsqQuestionKindEnum.SingleChoiceField]: 'SINGLE_CHOICE',
  [ISsqQuestionKindEnum.MultipleChoiceField]: 'MULTIPLE_CHOICE',
};

export const COMMON_FORM_DATA_NORMALIZER: CommonFieldNormalizerLookup = {
  MULTIPLE_CHOICE: {
    field: (args) => {
      if (args.name !== 'field') {
        throw new Error(
          '[Unreachable code]: We only handle "field" changes here'
        );
      }
      return {
        ...args.currentData,
        options: {
          ...(args.reset && args.value
            ? Object.keys(args.currentData.options).reduce<
                IFieldFormData['options']
              >((accumulator, current) => {
                accumulator[current] = {
                  field: false,
                  textbox: getDefaultAnswerTBValue(
                    args.currentData.options[current]?.textbox?.format
                  ),
                  references: [],
                  dropdown: [],
                };
                return accumulator;
              }, {})
            : args.currentData.options),
          [args.optionId]: {
            field: args.value,
            textbox: getDefaultAnswerTBValue(args.optionTextBoxFormat),
            references: [],
            dropdown: [],
          },
        },
      };
    },
    textbox: (options) => {
      return common.prepare(options)('textbox');
    },
    dropDownOptionIds: (options) => {
      return common.prepare(options)('dropdown');
    },
  },
  SINGLE_CHOICE: {
    field: ({ currentData, optionTextBoxFormat, optionId, value }) => {
      return {
        ...currentData,
        options: Object.entries(currentData.options).reduce(
          (nextFormData, [_optionId]) => {
            nextFormData[_optionId] = {
              field: Boolean(_optionId === optionId && value),
              textbox: getDefaultAnswerTBValue(optionTextBoxFormat),
              references: [],
              dropdown: [],
            };
            return nextFormData;
          },
          currentData.options
        ),
      };
    },
    textbox: (options) => {
      return common.prepare(options)('textbox');
    },
    dropDownOptionIds: (options) => {
      return common.prepare(options)('dropdown');
    },
  },
  SINGLE_FREEFORM: {
    field: (args) => {
      if (args.name !== 'field') {
        throw new Error(
          '[Unreachable code]: We only handle "field" changes here'
        );
      }
      return {
        ...args.currentData,
        options: {
          [args.optionId]: {
            field: args.value,
            references:
              args.currentData.options[args.optionId]?.references || [],
            textbox: undefined,
            dropdown: [],
          },
        },
      };
    },
    textbox: () => {
      throw new Error('SingleFreeFormField does not have textbox');
    },
    dropDownOptionIds: () => {
      throw new Error('SingleFreeFormField does not have textbox');
    },
  },
};

export const CUSTOM_FORM_DATA_NORMALIZER: IFormDataNormalizer = {
  [IQuestionKindEnum.MultipleChoiceField]: (options) => {
    if (options.name === 'references') {
      return common.prepare(options)('references');
    }
    throw new Error('MultipleChoiceField does not have references');
  },
  [IQuestionKindEnum.SingleChoiceField]: (options) => {
    if (options.name === 'references') {
      return common.prepare(options)('references');
    }
    throw new Error('SingleChoiceField does not have references');
  },
  [IQuestionKindEnum.SingleFreeFormField]: (options) => {
    if (options.name === 'references') {
      return common.prepare(options)('references');
    }
    throw new Error('SingleFreeFormField does not have references');
  },
  [ISsqQuestionKindEnum.MultipleChoiceField]: (options) => {
    if (options.name === 'documents' && isArrayOfResources(options.value)) {
      return {
        ...options.currentData,
        question: {
          documents: options.value,
        },
      };
    }
    throw new Error('MultipleChoiceField does not have references');
  },
  [ISsqQuestionKindEnum.SingleChoiceField]: (options) => {
    if (options.name === 'documents' && isArrayOfResources(options.value)) {
      return {
        ...options.currentData,
        question: {
          documents: options.value,
        },
      };
    }
    throw new Error('SingleChoiceField does not have references');
  },
  [ISsqQuestionKindEnum.SingleFreeFormField]: (options) => {
    if (options.name === 'documents' && isArrayOfResources(options.value)) {
      return {
        ...options.currentData,
        question: {
          documents: options.value,
        },
      };
    }
    throw new Error('SingleFreeFormField does not have references');
  },
};

export const getAnswerPayloadByKind: IPayloadNormalizer = {
  [IQuestionKindEnum.SingleFreeFormField]: ({ preparedData, optionId }) => {
    const value = preparedData.options[optionId]?.field;
    return {
      value: isIAnswerTextBoxValue(value) ? getTextBoxValue(value) : undefined,
      references:
        preparedData.options[optionId]?.references.map((id) => ({
          id,
        })) || [],
    };
  },
  [IQuestionKindEnum.SingleChoiceField]: ({ preparedData, optionId }) => {
    return {
      value: {
        optionId,
        dropDownOptionIds: preparedData.options[optionId]?.dropdown || [],
        references:
          preparedData.options[optionId]?.references.map((id) => ({
            id,
          })) || [],
        value: getTextBoxValue(preparedData.options[optionId]?.textbox),
      },
    };
  },
  [IQuestionKindEnum.MultipleChoiceField]: ({ preparedData }) => {
    return {
      values: Object.entries(preparedData.options)
        .filter(
          ([
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _,
            optionValues,
          ]) => optionValues.field === true
        )
        .map(([optionId, optionValues]) => {
          return {
            references: optionValues.references.map((id) => ({ id })),
            dropDownOptionIds: optionValues.dropdown,
            value: getTextBoxValue(optionValues.textbox),
            optionId,
          };
        }),
    };
  },
  [ISsqQuestionKindEnum.SingleFreeFormField]: ({ preparedData, optionId }) => {
    if (optionId) {
      const value = preparedData.options[optionId]?.field;
      return {
        value: isIAnswerTextBoxValue(value)
          ? getTextBoxValue(value)
          : undefined,
      };
    } else {
      return {
        documents:
          preparedData.question?.documents.map((resource) => ({
            id: resource.id,
          })) || [],
      };
    }
  },
  [ISsqQuestionKindEnum.SingleChoiceField]: ({ preparedData, optionId }) => {
    if (optionId) {
      return {
        value: {
          optionId,
          dropDownOptionIds: preparedData.options[optionId]?.dropdown || [],
          references: [],
          value: getTextBoxValue(preparedData.options[optionId]?.textbox),
        },
      };
    }
    return {
      documents:
        preparedData.question?.documents.map((resource) => ({
          id: resource.id,
        })) || [],
    };
  },
  [ISsqQuestionKindEnum.MultipleChoiceField]: ({ preparedData }) => {
    return {
      values: Object.entries(preparedData.options)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, optionValues]) => optionValues.field === true)
        .map(([optionId, optionValues]) => {
          return {
            references: [],
            dropDownOptionIds: optionValues.dropdown,
            value: getTextBoxValue(optionValues.textbox),
            optionId,
          };
        }),
      documents:
        preparedData.question?.documents.map((resource) => ({
          id: resource.id,
        })) || [],
    };
  },
};

export const getDefaultAnswerTBValue = (
  format?: AnswerValueFormatEnum
): IAnswerTextBoxValue | undefined => {
  return format ? DEFAULT_ANSWER_VALUE[format] : undefined;
};

export const DEFAULT_ANSWER_VALUE: Record<
  AnswerValueFormatEnum,
  IAnswerTextBoxValue | undefined
> = {
  [AnswerValueFormatEnum.FORMAT_DATE]: {
    date: undefined,
    format: AnswerValueFormatEnum.FORMAT_DATE,
  },
  [AnswerValueFormatEnum.FORMAT_MONETARY]: {
    amount: undefined,
    currency: 'EUR',
    format: AnswerValueFormatEnum.FORMAT_MONETARY,
  },
  [AnswerValueFormatEnum.FORMAT_NUMERICAL]: {
    value: undefined,
    format: AnswerValueFormatEnum.FORMAT_NUMERICAL,
  },
  [AnswerValueFormatEnum.FORMAT_SHORT_TEXT]: {
    text: undefined,
    format: AnswerValueFormatEnum.FORMAT_SHORT_TEXT,
  },
  [AnswerValueFormatEnum.FORMAT_LONG_TEXT]: {
    text: undefined,
    format: AnswerValueFormatEnum.FORMAT_LONG_TEXT,
  },
};

export const shouldShowProgress = ({
  id,
  isLoading,
  progress,
  classification,
}: {
  id: string;
  classification: 'themes' | 'criteria';
  isLoading: boolean;
  progress?: ISurveyProgress;
}): boolean => {
  return Boolean(
    isLoading === true ||
      (isLoading === false &&
        progress?.[classification][id]?.progress !== undefined)
  );
};

export const formatLink = (navigationParams: INavigationQuestion): string => {
  return `/survey/main/${navigationParams.theme}/${
    navigationParams.criterion
  }?question=${navigationParams.question || ''}`;
};
