import * as React from 'react';

const CONFIG = {
  BASE_URL: import.meta.env?.VITE_APP_BASE_URL || 'http://localhost:8080',
  APPLICATION_URL:
    import.meta.env?.VITE_APP_APPLICATION_URL || 'http://localhost:3001',
  CLIENT_ID: import.meta.env?.VITE_APP_CLIENT_ID || 'CLIENT_ID_IS_REQUIRED',
  CLIENT_SECRET:
    import.meta.env?.VITE_APP_CLIENT_SECRET || 'CLIENT_SECRET_IS_REQUIRED',
  APP_VERSION: import.meta.env?.VITE_APP_APP_VERSION || '<APP_VERSION>',
  NODE_ENV: import.meta.env?.MODE || 'development',
  SENTRY_AUTH_TOKEN:
    import.meta.env?.VITE_APP_SENTRY_AUTH_TOKEN ||
    'SENTRY_AUTH_TOKEN_IS_REQUIRED',
  SENTRY_DSN: import.meta.env?.VITE_APP_SENTRY_DSN,
  SENTRY_ENV: import.meta.env?.VITE_APP_SENTRY_ENV || 'dev',
  BASE_SOCKET_SERVER_URL: import.meta.env?.VITE_APP_SOCKET_SERVER_BASE_URL,
  IDLE_TIMEOUT: Number.parseInt(
    import.meta.env?.VITE_APP_VITE_APP_IDLE_TIMEOUT || '60'
  ),
  RECAPTCHA_SITE_KEY: import.meta.env?.VITE_APP_RECAPTCHA_SITE_KEY,
  UNLEASH_PROXY_URL:
    import.meta.env?.VITE_APP_UNLEASH_PROXY_URL || 'http://localhost:3001',
  UNLEASH_CLIENT_KEY: import.meta.env?.VITE_APP_UNLEASH_CLIENT_KEY || 'key',
  SUPPORT_EMAIL: 'support@inteliam.eu',

  DD_REPLAY_SAMPLING_RATE: Number.parseInt(
    import.meta.env?.VITE_APP_DD_REPLAY_SAMPLING_RATE || '100'
  ),

  AUTHORIZED_HTML_TAGS: [
    'a',
    'b',
    'blockquote',
    'br',
    'em',
    'h1',
    'h2',
    'img',
    'li',
    'ol',
    'p',
    's',
    'strong',
    'u',
    'ul',
  ] as Array<string>,
} as const;
export type ConfigContextType = typeof CONFIG;

const ConfigContext = React.createContext<ConfigContextType>(CONFIG);

function useConfig() {
  return React.useContext(ConfigContext);
}

export { ConfigContext, useConfig };
