import { showReportDialog } from '@sentry/react';
import * as React from 'react';

import Pages500 from '@core/pages/common/500';

import { FOErrorTracking } from '@core/utils';

import { ConfigContextType } from '@core/contexts';

interface Props {
  prop?: string;
  config: ConfigContextType;
}

interface State {
  // IS THIS THE CORRECT TYPE FOR THE state ?
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  Props & {
    children?: React.ReactNode;
  },
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount(): void {
    FOErrorTracking.initTracking(this.props.config);
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    FOErrorTracking.report(error, 'ErrorBoundary', errorInfo);
    // You can also log the error to an error reporting service
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Pages500>
          <button
            className='btn mr-2 rounded-md bg-error p-2 text-sm'
            onClick={() => showReportDialog()}
            // eslint-disable-next-line @inteliam/i18n/raw-text-detected
          >
            Report feedback
          </button>
        </Pages500>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
