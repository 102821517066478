import * as React from 'react';

import {
  Button,
  CheckMarkIcon,
  ErrorIcon,
  Dialog,
  DialogBody,
  WarningIcon,
  HelpIcon,
} from '@shared/components';

import {
  CONFIRM_CONTEXT_DEFAULT_OPTIONS,
  ConfirmerContext,
  IConfirmerDispatcher,
} from './confirm-context';
import { useI18n } from './i18n-context';
import { AlertType } from './notifier-context';

interface ConfirmOptions {
  type: AlertType | 'default';
  title?: string;
  description?: React.ReactNode;
  customButtons?: (args: {
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
  }) => React.ReactNode;
  style?: React.CSSProperties;
  onOpen?: (onClose: () => void) => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
  showCloseButton?: boolean;
  closeOnClickOutside?: boolean;
}

const ConfirmerProvider: React.FCC = ({ children, ...rest }) => {
  const { t } = useI18n();
  const cancelButtonRef = React.useRef<HTMLButtonElement>(null);

  const [{ customButtons, ...restOptions }, setOptions] =
    React.useState<ConfirmOptions>(CONFIRM_CONTEXT_DEFAULT_OPTIONS);
  const [resolveReject, setResolveReject] = React.useState<
    [resolve?: (value?: unknown) => void, reject?: (reason?: unknown) => void]
  >([]);
  const [resolve, reject] = resolveReject;

  const handleClose = React.useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = React.useCallback(() => {
    reject?.();
    handleClose();
  }, [reject, handleClose]);

  const handleConfirm = React.useCallback(() => {
    resolve?.();
    handleClose();
  }, [resolve, handleClose]);

  const confirm = React.useCallback(
    (options: Partial<ConfirmOptions> = {}) => {
      const { onOpen, ...rest } = options;
      onOpen?.(handleConfirm);

      return new Promise((resolve, reject) => {
        setOptions({ ...CONFIRM_CONTEXT_DEFAULT_OPTIONS, ...rest });
        setResolveReject([resolve, reject]);
        setTimeout(() => {
          cancelButtonRef.current?.focus();
        });
      });
    },
    [handleConfirm]
  );

  return (
    <ConfirmerContext.Provider value={confirm} {...rest}>
      {children}
      {resolveReject.length === 2 ? (
        <Dialog
          open
          onClose={handleCancel}
          backdrop='static'
          keyboard={false}
          isCentered
          size='sm'
        >
          <DialogBody data-cy='confirmation-modal'>
            <div className='my-2 flex items-center justify-center'>
              {restOptions.type === 'warning' && (
                <WarningIcon width={96} height={96} className='text-warning' />
              )}
              {restOptions.type === 'success' && (
                <CheckMarkIcon
                  width={96}
                  height={96}
                  className='text-success'
                />
              )}
              {restOptions.type === 'error' && (
                <ErrorIcon width={96} height={96} className='text-error' />
              )}
              {restOptions.type === 'info' && (
                <HelpIcon width={96} height={96} className='text-info' />
              )}
            </div>
            <h3 className='text-center font-semibold'>
              {restOptions.title ?? t(CONFIRM_CONTEXT_DEFAULT_OPTIONS.title)}
            </h3>

            <div className='text-center'>{restOptions.description}</div>
            <div className='mt-5 flex items-center justify-evenly'>
              {customButtons?.({
                onClose: handleClose,
                onConfirm: handleConfirm,
                onCancel: handleCancel,
              }) || (
                <>
                  {restOptions.showCancel && (
                    <Button
                      id='cancel-modal'
                      className='mr-4'
                      variant='link'
                      onClick={handleCancel}
                      ref={cancelButtonRef}
                    >
                      {restOptions.cancelBtnText ?? t('Cancel')}
                    </Button>
                  )}
                  {restOptions.showConfirm && (
                    <Button
                      id='confirm-modal'
                      variant='inteliam'
                      className='ml-4'
                      onClick={handleConfirm}
                    >
                      {restOptions.confirmBtnText ?? t('Confirm')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </DialogBody>
        </Dialog>
      ) : undefined}
    </ConfirmerContext.Provider>
  );
};

function useConfirm(): IConfirmerDispatcher {
  const context = React.useContext(ConfirmerContext);
  if (context === undefined) {
    throw new Error('useConfirm must be used within a ConfirmerProvider');
  }
  return context;
}

export { ConfirmerProvider, useConfirm };
