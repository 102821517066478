import * as React from 'react';

const fakeWords = [
  'Lorem',
  'ipsum',
  'dolor',
  'sit',
  'amet',
  'consectetur',
  'adipiscing',
  'elit',
  'sed',
];

const generateFakeText = (words: number) => {
  const fakeText = [];
  for (let index = 0; index < words; index++) {
    fakeText.push(fakeWords[Math.floor(Math.random() * fakeWords.length)]);
  }
  return fakeText.join(' ');
};

const TextLoader: React.FCC<{ rows?: number; words?: number }> = ({
  rows = 1,
  words = 5,
}) => {
  return (
    <>
      {Array.from({ length: rows })
        .fill(0)
        .map((_, index) => (
          <div key={index}>
            <div className='inline animate-pulse bg-slate-200'>
              <span className='leading-normal opacity-0'>
                {generateFakeText(words)}
              </span>
            </div>
          </div>
        ))}
    </>
  );
};

export default TextLoader;
