import { TranslationKeys } from '@shared/types';

const ErrorCodesEnum = {
  E0000: 'E0000',
  E0001: 'E0001',
  E0002: 'E0002',
  E0003: 'E0003',
  E0004: 'E0004',
  E0005: 'E0005',
  E0006: 'E0006',
  E0007: 'E0007',
  E0008: 'E0008',
  E0009: 'E0009',

  // E5000: 'E5000',
  // auth
  E6000: 'E6000',
  E6001: 'E6001',
  E6002: 'E6002',
  E6003: 'E6003',
  E6004: 'E6004',
  E6005: 'E6005',
  E6006: 'E6006',

  // Reset password
  E0100: 'E0100',
  E0101: 'E0101',
  E0102: 'E0102',

  E9999998: 'E9999998',
  // Local
  E0001L: 'E0001L',
  E0002L: 'E0002L',
} as const;
export type ErrorCodesEnumType =
  typeof ErrorCodesEnum[keyof typeof ErrorCodesEnum];

const errorCodeEnumProxyHandler = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(target: object, propertyKey: PropertyKey, receiver?: any) {
    // eslint-disable-next-line prefer-rest-params
    const value = Reflect.get(target, propertyKey, receiver);
    if (value === undefined)
      return 'An internal error occurred, please try again later or contact Inteliam support';
    return value;
  },
};
export const ErrorCodesLookup = new Proxy<
  Record<
    ErrorCodesEnumType,
    { title: TranslationKeys; description?: TranslationKeys }
  >
>(
  {
    E0000: {
      title: 'There are fields that requires your attention',
    },
    E0001: {
      title: 'This field cannot be empty',
    },
    E0002: {
      title: 'This value is already in use, please choose another one',
    },
    E0003: {
      title: 'Please provide a valid email address',
    },
    E0004: {
      title: 'Please select one of the provided options',
    },
    E0005: {
      title: 'The field should have at least {{count}} options',
    },
    E0006: {
      title: 'Please provide a valid URL',
    },
    E0007: {
      title: 'This field must be a positive number',
    },
    E0008: {
      title:
        'The password must contain at least eight characters, one character, one number and one special character [!@#$%^&*+=_(){}/?.,<>|;:\'"~-].',
    },
    E0009: {
      title: 'Passwords do not match',
    },

    // E5000: {
    //   title: 'fod.my_colleagues.form.messages.add_colleague.error',
    // },
    E6000: {
      title:
        'Login failed. Your email or/and password are invalid, please try again!',
    },
    E6001: {
      title: 'Too many requests!',
    },
    E6002: {
      title: 'Your account is disabled, please contact inteliam',
    },
    E6003: {
      title:
        'Login failed. Your email or/and password are invalid, please try again!',
    },
    E6004: {
      title: 'Invalid CAPTCHA token',
    },
    E6005: {
      title: 'User not found, please verify your email address',
    },
    E6006: {
      title:
        'Your account is not configured to use SSO. Please contact inteliam support at support@inteliam.eu',
    },

    // local
    E0001L: {
      title: 'The file is too big (Max supported size is 20 MB)',
    },
    E0002L: {
      title:
        'File type not supported (Supported types: pdf/word/excel/image/txt/csv)',
    },

    E0100: {
      title: 'Unable to process your request. Please contact Inteliam.',
    },

    E0101: {
      title:
        'The password you entered is similar to you previous one. Please enter a different password.',
    },
    E0102: {
      title: 'Unable to find a password recovery request.',
    },
    E9999998: {
      title: "You don't have the required permissions to access this feature",
    },
  },
  errorCodeEnumProxyHandler
);
