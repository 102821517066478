import {
  Configuration,
  ProtocolMode,
  PublicClientApplication,
} from '@azure/msal-browser';
import { FeatureFlagProvider } from '@inteliam/foundation/lib/contexts';
import {
  QueryClient,
  QueryClientProvider,
} from '@inteliam/foundation/lib/hooks';

import { MsalProvider } from '@azure/msal-react';
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {
  AuthProvider,
  ConfirmerProvider,
  I18nProvider,
  NotifierProvider,
  ConfigContext,
} from '@core/contexts';

import { ErrorBoundary } from '@shared/components';

import { Constants } from '@shared/utils';

const queryClient = new QueryClient();

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: 'a053bfac-5b2a-4341-b144-15dccc9cf704',
    authority: 'https://login.microsoftonline.com/common',
    protocolMode: ProtocolMode.AAD,
    redirectUri: Constants.APPLICATION_URL,
    postLogoutRedirectUri: Constants.APPLICATION_URL,
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false,
    secureCookies: true,
  },
};

const publicClientApplication = new PublicClientApplication(configuration);

const AppProviders: React.FCC = ({ children }) => {
  return (
    <ConfigContext.Consumer>
      {(config) => (
        <ErrorBoundary config={config}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <MsalProvider instance={publicClientApplication}>
                <AuthProvider>
                  <NotifierProvider>
                    <Routes>
                      <Route
                        path='*'
                        element={
                          <I18nProvider>
                            <ConfirmerProvider>
                              <FeatureFlagProvider
                                config={{
                                  baseUrl: config.BASE_URL,
                                  pid: '25498580',
                                }}
                              >
                                {children}
                              </FeatureFlagProvider>
                            </ConfirmerProvider>
                          </I18nProvider>
                        }
                      />
                    </Routes>
                  </NotifierProvider>
                </AuthProvider>
              </MsalProvider>
            </Router>
          </QueryClientProvider>
        </ErrorBoundary>
      )}
    </ConfigContext.Consumer>
  );
};
export default AppProviders;
