import { IScoreCardStatusEnum } from '@inteliam/foundation/lib/enums';

import {
  IAnyAssessmentReview,
  IAPIResponse,
  IAssessmentHighlight,
  IPaginationResponse,
  IScoreCardWithBenchmark,
} from '@inteliam/foundation/lib/types';

interface IThemeBenchmark {
  average: number;
  theme: string;
}

interface ICriterionBenchmark {
  average: number;
  criterion: string;
}

export interface IBenchmarkResponse {
  global: number;
  byTheme: Array<IThemeBenchmark>;
  byCriterion: Array<ICriterionBenchmark>;
}

export interface IDistributionGraphDataset {
  boundary: number;
  average: number;
  count: number;
  distributors: Array<{
    id: string;
    name: string;
    score: number;
  }>;
}

const BenchmarkScopeEnum = {
  ALL_INTELIAM_DB: 'ALL_INTELIAM_DB',
  MY_FAVORITES: 'MY_FAVORITES',
  MY_PORTFOLIO: 'MY_PORTFOLIO',
} as const;

export type BenchmarkScopeType = keyof typeof BenchmarkScopeEnum;

export interface IBenchmarkQuery {
  scope: BenchmarkScopeType;
  classification: 1 | 2 | 3;
  region: 'ALL_REGIONS' | string;
}

export type IScoreCardResponse = IAPIResponse<IScoreCardWithBenchmark>;
export type IAssessmentReviewResponse =
  IPaginationResponse<IAnyAssessmentReview>;
export type IAssessmentHighlightResponse = IAPIResponse<
  Array<IAssessmentHighlight>
>;
export type IDistributionGraphResponse = IAPIResponse<
  Array<IDistributionGraphDataset>
>;

export interface IScoreCardHistoryQuery {
  statuses: Array<IScoreCardStatusEnum>;
}

export enum IScoreCardTrendEnum {
  UP = 'UP',
  DOWN = 'DOWN',
  FLAT = 'FLAT',
}
export type ScoreCardTrend =
  | IScoreCardTrendEnum.UP
  | IScoreCardTrendEnum.DOWN
  | IScoreCardTrendEnum.FLAT;
