import clsx from 'clsx';

import * as React from 'react';
import * as ReactDom from 'react-dom';

import { BoxCard, Text } from '../design-system';
import './style.scss';

type FullScreenProps = {
  size: 'fullscreen';
  /**
   * Do no pass isCentered with fullscreen size
   */
  isCentered?: never;
};
type SizeProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  isCentered?: boolean;
};

type Props = (FullScreenProps | SizeProps) & {
  /**
   * Allow to close the modal with ESC key (better turn off for modals with forms)
   */
  keyboard?: boolean;
  backdrop?: boolean | 'static';

  open: boolean;
  onClose: () => void;
};
const portalContainer = document.getElementById('portal') as HTMLElement;
// https://github.com/focus-trap/focus-trap-react Maybe some day we will have an accessible modal
export const Dialog: React.FCC<Props> = ({
  open,
  onClose,
  children,
  backdrop = 'static',
  keyboard = false,
  isCentered = false,
  size = 'xl',
}) => {
  // event listener to close on ESC
  React.useEffect(() => {
    if (!keyboard) {
      return;
    }
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        onClose();
      }
    };
    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [keyboard, onClose, open]);

  if (!open) {
    return <></>;
  }

  return ReactDom.createPortal(
    <div
      className={clsx(
        'fade fixed top-0 left-0 z-[50] flex h-screen w-screen justify-center overflow-y-auto overflow-x-hidden ',
        {
          'items-center': isCentered,
        }
      )}
      tabIndex={-1}
    >
      {/* Overlay */}
      {(backdrop === 'static' || backdrop) && (
        <div
          className='fixed h-screen w-screen bg-black bg-opacity-50 backdrop-blur-sm'
          onClick={() => {
            onClose();
          }}
          tabIndex={-1}
        ></div>
      )}
      <BoxCard
        className={clsx(
          'relative z-[51] flex flex-col overflow-y-auto overflow-x-hidden rounded-lg bg-secondary p-4 outline-none',
          {
            'w-1/3': size === 'sm',
            'w-1/2': size === 'md',
            'w-2/3': size === 'lg',
            'w-4/5': size === 'xl',
            'h-full w-full': size === 'fullscreen',

            'my-7': !isCentered && size !== 'fullscreen',
          }
        )}
        tabIndex={-1}

        // toggle={onClose}
        // {...{ backdrop, keyboard }}
        // centered={isCentered}
      >
        {children}
      </BoxCard>
    </div>,
    portalContainer
  );
};

export const DialogHeader: React.FCC<{ onClose: () => void }> = ({
  onClose,
  children,
}) => {
  return (
    <div className='mb-3 flex items-center justify-between border-b-2 border-b-gray-300'>
      <Text heading='heading2' className='m-0 w-full text-base'>
        {children}
      </Text>
      <button
        onClick={onClose}
        type='button'
        className='cursor-pointer border-none bg-transparent text-xl outline-none'
        data-dismiss='modal'
        aria-label='Close'
      >
        {/* eslint-disable-next-line @inteliam/i18n/raw-text-detected */}
        <span>&times;</span>
      </button>
    </div>
  );
};

export const DialogBody: React.FCC = ({ children, ...rest }) => {
  return (
    <div {...rest} className='modal-body'>
      {children}
    </div>
  );
};
