import { useOnMount } from '@inteliam/foundation/lib/hooks';
import { AuthHelpers } from '@inteliam/foundation/lib/utils';

import { useIsAuthenticated } from '@azure/msal-react';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { useMutation } from '@core/queries';

import { SecurityApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import { useMsftSSO } from '@shared/hooks';

import { BaseAxiosErrorResponse } from '@inteliam/foundation/lib/types';

// TO redirect a user, never call logout method always redirect to /logout
const Logout: React.FCC = () => {
  const { logout } = useEssentials();
  const isAuthenticated = useIsAuthenticated();
  const { getInstance } = useMsftSSO();
  const { mutateAsync } = useMutation<void, BaseAxiosErrorResponse, string>(
    SecurityApi.logout,
    {}
  );

  useOnMount(() => {
    const token = AuthHelpers.getAccessToken();
    if (isAuthenticated) {
      getInstance()
        .logoutRedirect()
        .catch(() => {});
      getInstance()
        .clearCache()
        .catch(() => {});
    }
    if (token) mutateAsync(token).catch(() => {});
    logout().catch(() => {});
  });

  return <Navigate to='/login' />;
};

export default Logout;
