import {
  AnyEnterpriseRoleType,
  ICompanyKindEnum,
  IDistributorRolesEnum,
  ILanguagesEnum,
  ITitlesEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { DISTRIBUTOR_ROLES } from '@core/enums';

import {
  IBaseDistributorUserPayload,
  IDistributorUserForm,
  IUpdateDistributorUserPayload,
  UserUiStatus,
} from '@core/types';

import type {
  IAR,
  IContactInfo,
  IFODistributorUser,
  IFOUser,
  JwtFOUser,
  ValidationError,
} from '@inteliam/foundation/lib/types';

import { FOErrorTracking } from '.';

export const hasRoles = (
  checkAgainst:
    | (AnyEnterpriseRoleType | IDistributorRolesEnum)
    | Array<AnyEnterpriseRoleType | IDistributorRolesEnum>,
  user: IFOUser | JwtFOUser,
  shouldHaveAll = false
): boolean => {
  if (Array.isArray(checkAgainst)) {
    return checkAgainst[shouldHaveAll ? 'every' : 'some']((role) =>
      user.roles.includes(role)
    );
  }

  return user.roles.includes(checkAgainst);
};

export interface IFormattedUser {
  label: string;
  value: string;
}

export const formatShortName = (contactInfo?: IContactInfo): string => {
  let result = '';

  if (contactInfo === undefined) {
    return '';
  }

  if (contactInfo?.firstName) {
    result += contactInfo?.firstName;

    if (contactInfo?.lastName) {
      result += ` ${contactInfo?.lastName[0] as string}.`;
    }
  } else {
    if (contactInfo?.lastName) {
      result += contactInfo?.lastName;
    }
  }

  return result;
};

export const formatFullName = (contactInfo?: IContactInfo): string => {
  let result = '';
  if (contactInfo === undefined) {
    return '';
  }
  if (contactInfo?.firstName) {
    result += contactInfo?.firstName;
  }
  if (contactInfo?.lastName) {
    result += ' ' + contactInfo?.lastName;
  }
  return result;
};

export const formatUser = (user: IFOUser): IFormattedUser => {
  return {
    label: `${user?.contactInfo.firstName || ''} ${
      user?.contactInfo.lastName || ''
    }`,
    value: user.id,
  };
};

const DISTRIBUTOR_ROLES_VALUES = new Set(
  DISTRIBUTOR_ROLES.map((role) => role.value)
);

export const getUiAccountStatus = (status: IUserStatusEnum): UserUiStatus => {
  if (IUserStatusEnum.STATUS_ENABLED === status) {
    return IUserStatusEnum.STATUS_ENABLED;
  }
  // we consider any other status as disabled (pending, locker,...)
  return IUserStatusEnum.STATUS_DISABLED;
};

const DEFAULT_CONTACT_FORM = {
  title: ITitlesEnum.MR,
  phoneNumber: '',
  email: '',
  language: ILanguagesEnum.EN_GB,
};
export const normalize = {
  validationErrorsToFormErrors: (
    errors: Array<ValidationError>
  ): Array<ValidationError> => {
    return errors.map((error) => {
      return {
        ...error,
        propertyPath: `user.${error.propertyPath}`,
      };
    });
  },
  userToFormData: (
    user: IFODistributorUser,
    inSurveyAssessmentRequest?: IAR
  ): IDistributorUserForm => {
    const distributorIsInSurveyStatus = inSurveyAssessmentRequest !== undefined;
    return {
      user: {
        id: user.id,
        contactInfo: user.contactInfo,
        roles: [
          user.roles?.filter((role) =>
            DISTRIBUTOR_ROLES_VALUES.has(role)
          )?.[0] || IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED,
        ],
      },
      primaryContact: Boolean(user.primaryContact),
      questionnaireContact:
        user?.questionnaireContact ||
        (distributorIsInSurveyStatus &&
          Helpers.ensureValueAsArray(
            user?.questionnaireContactFor?.filter(
              (ownedAr) => ownedAr.id === inSurveyAssessmentRequest?.id
            )
          ).length > 0),
    };
  },
  formDataToBasePayload: (
    formData: IDistributorUserForm,
    companyId: string
  ): IBaseDistributorUserPayload => {
    return {
      ...formData,
      user: {
        ...formData.user,
        company: { id: companyId },
      },
      questionnaireContactFor: formData.questionnaireContactFor?.map((id) => ({
        id,
      })),
      kind: ICompanyKindEnum.DISTRIBUTOR,
    };
  },
  basePayloadToUpdatePayload: (
    payload: IBaseDistributorUserPayload,
    databaseUser: IFODistributorUser,
    inSurveyAssessmentRequest?: IAR
  ): IUpdateDistributorUserPayload => {
    if (databaseUser?.company.id)
      return {
        ...payload,
        user: {
          ...payload.user,
          company: { id: databaseUser.company.id },
          id: databaseUser.id,
        },
        questionnaireContactFor:
          payload.questionnaireContact && inSurveyAssessmentRequest
            ? [
                ...Helpers.ensureValueAsArray(
                  databaseUser.questionnaireContactFor?.map((ar) => ({
                    id: ar.id,
                  }))
                ),
                { id: inSurveyAssessmentRequest.id },
              ]
            : databaseUser.questionnaireContactFor?.map((ar) => ({
                id: ar.id,
              })),
      };
    FOErrorTracking.report(
      new Error(
        'Cannot normalize user : company id not found [basePayloadToUpdatePayload]'
      )
    );
    throw new Error('company id not found');
  },
  initialFormData: (): IDistributorUserForm => {
    return {
      user: {
        contactInfo: DEFAULT_CONTACT_FORM,
        roles: [IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED],
      },
      questionnaireContactFor: [],
      questionnaireContact: false,
      primaryContact: false,
    };
  },
  getUserRoleBeforeUpdate: (
    payload: IUpdateDistributorUserPayload,
    user: IFODistributorUser
  ): IFODistributorUser['roles'] => {
    return user.roles?.includes(IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD)
      ? payload.user.roles
      : user.roles;
  },
};
