import * as React from 'react';

const Icon: React.FCC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='60'
        height='60'
      >
        <rect width='60' height='60' fill='white' />
      </mask>
      <g mask='url(#mask0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M27.5013 25H32.5013V37.5H27.5013V25ZM27.501 40H32.501V45H27.501V40Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M34.4203 10.5007C33.5503 8.8632 31.8553 7.8457 30.0003 7.8457C28.1453 7.8457 26.4503 8.8632 25.5803 10.5032L7.23528 45.1607C6.40778 46.7257 6.45778 48.5632 7.37028 50.0807C8.28278 51.5957 9.88278 52.5007 11.6528 52.5007H48.3478C50.1178 52.5007 51.7203 51.5957 52.6328 50.0807C53.5428 48.5657 53.5953 46.7257 52.7678 45.1607L34.4203 10.5007ZM11.6533 47.5008L30.0008 12.8433L48.3608 47.5008H11.6533Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default Icon;
