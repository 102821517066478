import clsx from 'clsx';

import * as React from 'react';

import { DynamicTagAware } from '@inteliam/foundation/lib/types';

import { Text } from '../typography';

const __DEFAULT_ELEMENT__ = 'div' as const;
type BoxOwnProps<E extends React.ElementType = typeof __DEFAULT_ELEMENT__> = {
  component?: E;
};

type BoxProps<E extends React.ElementType> = BoxOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof BoxOwnProps>;
function InnerBox<T extends React.ElementType = typeof __DEFAULT_ELEMENT__>(
  { component: Component, ...props }: BoxProps<T>,
  ref: React.Ref<HTMLDivElement>
): React.ReactElement {
  const ComponentToUse = Component || __DEFAULT_ELEMENT__;
  return (
    <ComponentToUse
      ref={ref}
      {...props}
      className={clsx('relative', props.className)}
      id={props.id}
      data-cy={props.id}
    />
  );
}

export const Box = React.forwardRef(InnerBox);

// To be renamed
export const BoxCard: React.FCC<DynamicTagAware> = ({
  component: Component = 'div',
  children,
  className,
  ...rest
}) => {
  return (
    <Component
      className={clsx(
        'relative rounded-md border border-solid border-gray-200 bg-secondary p-3',
        className,
        {
          'border-gray-200': document.body.dataset.theme === 'light',
          'border-gray-400': document.body.dataset.theme === 'dark',
        }
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

type TextProps = React.ComponentProps<typeof Text>;

export const BoxCardHeader: React.FCC<
  DynamicTagAware & Omit<TextProps, 'component'>
> = ({ children, ...rest }) => {
  return (
    <Text
      {...rest}
      heading='heading3'
      weight='semi-bold'
      className='mb-3 flex items-center text-title'
    >
      {children}
    </Text>
  );
};

type FlexProx = DynamicTagAware & {
  direction?: 'row' | 'column';
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  grow?: number;
  shrink?: number;
  basis?: string;
  order?: number;
};

export const Flex: React.FCC<FlexProx> = ({
  component: Component = 'div',
  children,
  direction = 'row',
  justify = 'start',
  align = 'stretch',
  wrap = 'nowrap',
  grow = 0,
  shrink = 1,
  basis = 'auto',
  order = 0,
  className = '',
  style,
  ...rest
}) => {
  return (
    <Component
      className={clsx(
        'flex',
        {
          'flex-col': direction === 'column',
          'flex-row': direction === 'row',
          'justify-start': justify === 'start',
          'justify-end': justify === 'end',
          'justify-center': justify === 'center',
          'justify-between': justify === 'between',
          'justify-around': justify === 'around',
          'justify-evenly': justify === 'evenly',
          'items-start': align === 'start',
          'items-end': align === 'end',
          'items-center': align === 'center',
          'items-stretch': align === 'stretch',
          'items-baseline': align === 'baseline',
          'flex-nowrap': wrap === 'nowrap',
          'flex-wrap': wrap === 'wrap',
          'flex-wrap-reverse': wrap === 'wrap-reverse',
        },
        className
      )}
      style={{
        flexGrow: grow,
        flexShrink: shrink,
        flexBasis: basis,
        order: order,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};
