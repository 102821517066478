// TODO handle mode : dark vs light

export const PRIMARY = '#43a336';
export const GREEN_CELERY = '#A8CB60';
export const GRAY_FORM = '#ced4da';
export const GRAY_200 = '#eff2f7';
export const GRAY_700 = '#495050';
// SCORE CARD
export const ADVANCED = '#43a336';
export const SATISFACTORY = '#A8CB60';
export const MODERATE = '#F4B561';
export const NEEDS_IMPROVEMENT = '#FF9B4C';
export const INACTIVE = '#DBDBDB';
