import * as React from 'react';

const Icon: React.FCC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill='none'
      height={props.height || 16}
      width={props.width || 16}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m15.4512 12.1449-.297-2.86147c-.0343-.31411-.4196-.44908-.6405-.22822l-.7018.70187-4.41979-4.41974c-.2773-.27731-.76566-.27731-1.04297 0l-2.43441 2.43441-4.11298-4.11544c-.14234-.14233-.33129-.21596-.52026-.21596-.18896 0-.377913.07363-.520252.21596-.287119.28713-.287119.75339 0 1.04297l4.633242 4.63324c.27731.2773.76566.2773 1.04051 0l2.43687-2.43441 3.89954 3.89949-.8222.8221c-.2233.2233-.0883.6086.2258.6405l2.8614.2969c.2381.027.4393-.1742.4148-.4122z'
        fill='#eb5757'
      />
    </svg>
  );
};

export default Icon;
