import { isNil } from 'lodash-es';

import { SCORE_PERFORMANCE } from '@core/enums';

import { IScoreCardTrendEnum, ScoreCardTrend } from '@core/types';

import { TrendDownIcon, TrendFlatIcon, TrendUpIcon } from '@shared/components';

import { COLORS } from '@shared/styles';
import { TranslationKeys } from '@shared/types';

import { IScoreCard } from '@inteliam/foundation/lib/types';

export const COLOR_BY_PERFORMANCE = {
  [SCORE_PERFORMANCE.ADVANCED]: COLORS.ADVANCED,
  [SCORE_PERFORMANCE.SATISFACTORY]: COLORS.SATISFACTORY,
  [SCORE_PERFORMANCE.MODERATE]: COLORS.MODERATE,
  [SCORE_PERFORMANCE.NEEDS_IMPROVEMENT]: COLORS.NEEDS_IMPROVEMENT,
};

type Performances = keyof typeof COLOR_BY_PERFORMANCE;

export const getColorForScore = (score: number): string => {
  return COLOR_BY_PERFORMANCE[getScorePerformanceGroup(score)];
};

export const getScorePerformanceGroup = (score: number): Performances => {
  if (score >= 75) return SCORE_PERFORMANCE.ADVANCED;
  else if (score >= 50) return SCORE_PERFORMANCE.SATISFACTORY;
  else if (score >= 25) return SCORE_PERFORMANCE.MODERATE;
  else return SCORE_PERFORMANCE.NEEDS_IMPROVEMENT;
};

export const getColorClassForScore = (score: number): string => {
  if (score >= 75) return 'text-advanced';
  if (score >= 50) return 'text-satisfactory';
  if (score >= 25) return 'text-moderate';
  return 'text-needs-improvement';
};

type Definition = {
  [key: string]: { color: string; label: string };
};

export const performanceLookup: Definition = {
  ADVANCED: {
    color: getColorForScore(76),
    label: 'Advanced',
  },
  SATISFACTORY: {
    color: getColorForScore(51),
    label: 'Satisfactory',
  },
  MODERATE: {
    color: getColorForScore(26),
    label: 'Moderate',
  },
  NEEDS_IMPROVEMENT: {
    color: getColorForScore(0),
    label: 'Needs improvement',
  },
};

export const getExpirationLabel = (scoreCard?: IScoreCard): TranslationKeys => {
  return scoreCard?.expired
    ? 'Expired since: {{date}}'
    : 'Expires on: {{date}}';
};

export const getTrendToolTipLabel = (
  trend?: ScoreCardTrend
): TranslationKeys => {
  if (trend === IScoreCardTrendEnum.UP) {
    return 'Up from {{previousValue}} in the previous evaluation ({{year}})';
  }
  if (trend === IScoreCardTrendEnum.DOWN) {
    return 'Down from {{previousValue}} in the previous evaluation ({{year}})';
  }

  return 'Same as in the previous evaluation';
};

export const TrendIcons = (): {
  [key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>;
} => ({
  UP: TrendUpIcon,
  DOWN: TrendDownIcon,
  FLAT: TrendFlatIcon,
});

export const getTrend = (
  currentValue?: number,
  previousValue?: number
): IScoreCardTrendEnum => {
  if (isNil(currentValue) || isNil(previousValue)) {
    return IScoreCardTrendEnum.FLAT;
  } else if (Math.round(currentValue) > Math.round(previousValue)) {
    return IScoreCardTrendEnum.UP;
  } else if (Math.round(currentValue) < Math.round(previousValue)) {
    return IScoreCardTrendEnum.DOWN;
  } else {
    return IScoreCardTrendEnum.FLAT;
  }
};
