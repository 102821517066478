import * as React from 'react';

const Icon: React.FCC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill='none'
      height={props.height || 16}
      width={props.width || 16}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='2' y='7' width='12' height='2' rx='1' ry='1' fill='#828282' />
    </svg>
  );
};

export default Icon;
