import { AuthClient } from '@inteliam/foundation/lib/utils';

import { Constants } from '@shared/utils';

import type { JwtFOUser } from '@inteliam/foundation/lib/types';

// singleton
let authClientInstance: AuthClient<JwtFOUser> | undefined = undefined;

const getAuthClientInstance = (): AuthClient<JwtFOUser> => {
  if (authClientInstance) {
    return authClientInstance;
  }
  authClientInstance = new AuthClient<JwtFOUser>(
    Constants.BASE_URL,
    Constants.CLIENT_ID,
    Constants.CLIENT_SECRET
  );
  return authClientInstance;
};

export { getAuthClientInstance };
